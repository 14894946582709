/* eslint-disable no-underscore-dangle */
import { graphql, Link } from 'gatsby';
import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Loki from '../src/components/Loki/lokipane';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import DownGreenArrowIcon from '../src/images/TheBeatchallenge/down-green-arrow.svg';
import LaLiga3Desktop from '../src/images/TheBeatchallenge/la-liga-3-desktop.png';
import LaLiga3 from '../src/images/TheBeatchallenge/la-liga-3.png';
import BannerTheBeatChallenge from '../src/images/TheBeatchallenge/la-liga-jm1.jpg';
import LeftGreenArrowIcon from '../src/images/TheBeatchallenge/left-green-arrow.svg';
import QR from '../src/images/TheBeatchallenge/qr.png';
import RightGreenArrowIcon from '../src/images/TheBeatchallenge/right-green-arrow.svg';
import TheBeatChallenge1 from '../src/images/TheBeatchallenge/The-Beat-Challenge-1.png';
import TheBeatChallenge2 from '../src/images/TheBeatchallenge/The-Beat-Challenge-2.png';
import TheBeatChallenge3 from '../src/images/TheBeatchallenge/The-Beat-Challenge-3.png';
import TheBeatChallenge4 from '../src/images/TheBeatchallenge/The-Beat-Challenge-4.png';
import Layout from '../src/components/Layout/Layout';
import styles from './laliga-thebeatchallenge.module.scss';

const DownGreenArrow = ({ optStyle = '' }) => (
  <img
    className={`${styles.downGreenArrow} ${optStyle}`}
    src={DownGreenArrowIcon}
    alt="downGreeArrow"
  />
);
const LeftGreenArrow = ({ optStyle = '' }) => (
  <img className={optStyle} src={LeftGreenArrowIcon} alt="leftGreeArrow" />
);
const RightGreenArrow = ({ optStyle = '' }) => (
  <img className={optStyle} src={RightGreenArrowIcon} alt="rightGreeArrow" />
);

const CtaButton = ({ optStyle = '', locale }) => (
  <Link
    className={`${styles.ctaButton} ${styles.bold} ${optStyle}`}
    href={buildFunnelUrl(locale, 'tickets')}
    target="blank"
  >
    {tt('COMPRAR ENTRADAS', locale)}
  </Link>
);
const RestaurantCtaButton = ({ optStyle = '', locale }) => (
  <Link
    className={`${styles.resCtaButton} ${styles.bold} ${optStyle}`}
    href={tt('https://www.laliga29s.com/restaurante/laliga-twentynines-portaventuraworld', locale)}
    target="blank"
  >
    {tt('Reservar ahora', locale)}
  </Link>
);

const TheBeatChallengeBanner = ({ locale }) => (
  <section className={styles.banner}>
    <div className={styles.bannerWrapper}>
      <img
        className={styles.bannerImage}
        src={BannerTheBeatChallenge}
        alt="the-beat-challenge-banner"
      />
      <div className={styles.bannerContent}>
        <p className={styles.bannerText}>
          <span>
            {tt(
              'Descubre una nueva experiencia deportiva única y déjate sorprender por dos formas innovadoras de entender y disfrutar de la competición: la app',
              locale
            )}
          </span>
          <span className={styles.bannerSpan}>{`“${tt('The Beat Challenge', locale)}” `}</span>
          {`${tt('y el restaurante', locale)} `}
          <span className={styles.bold}>{tt('LaLiga TwentyNine’s PortAventura World.')}</span>
        </p>
      </div>
    </div>
  </section>
);

const TheBeatChallengeContent = ({ locale }) => (
  <section className={styles.content}>
    <div className={styles.contentStyle}>
      <div className={styles.contentWrapper}>
        <img src={TheBeatChallenge1} alt="TheBeatChallenge1" />
        <div>
          <LeftGreenArrow optStyle={`${styles.mobileHidden} ${styles.marginBotton}`} />
          <p className={styles.contentText}>
            {tt(
              'Esta nueva experiencia se complementa con un videojuego inmersivo, donde el universo digital y la visita al parque se fusionan para completar múltiples desafíos futbolísticos.',
              locale
            )}
            <span className={`${styles.contentSpan} ${styles.bold}`}>
              {`“${tt('LaLiga TwentyNine’s PortAventura World.', locale)}”`}
            </span>
          </p>
        </div>
        <DownGreenArrow optStyle={styles.desktopHidden} />
      </div>
      <div className={styles.contentWrapper}>
        <img src={TheBeatChallenge2} alt="TheBeatChallenge2" />
        <div>
          <p className={styles.contentText}>
            {tt(
              'Personaliza tu avatar, elige tu equipo favorito y empieza a jugar al conjunto de minijuegos de habilidades futbolísticas para coger experiencia, subir de categoría y desbloquear ítems para tu personaje. El videojuego contiene minijuegos y experiencias con la tecnología de realidad aumentada (RA) y se podrá jugar tanto en el resort como fuera de él.',
              locale
            )}
          </p>
          <RightGreenArrow optStyle={`${styles.mobileHidden} ${styles.marginTop}`} />
        </div>
        <DownGreenArrow optStyle={styles.desktopHidden} />
      </div>
      <div className={styles.contentWrapper}>
        <img src={TheBeatChallenge3} alt="TheBeatChallenge3" />
        <div>
          <p className={styles.contentText}>
            {tt(
              'Visita PortAventura World y desbloquea todas las posibilidades del juego: disfruta de los retos que se plantean en cada una de las áreas temáticas de PortAventura Park, enfréntate a jugadores de LaLiga, supera el reto Ultimate Challenge y conviértete en la nueva leyenda de LaLiga.',
              locale
            )}
          </p>
          <LeftGreenArrow optStyle={`${styles.mobileHidden} ${styles.marginTop}`} />
        </div>
        <DownGreenArrow optStyle={styles.desktopHidden} />
      </div>
      <div className={styles.contentWrapper}>
        <img src={TheBeatChallenge4} alt="TheBeatChallenge4" />
        <div className={styles.center}>
          <p className={styles.contentText}>
            <span className={`${styles.contentSpan} ${styles.bold}`}>
              {tt('Responde a la llamada de The Beat Challenge', locale)}
            </span>
            {tt(
              'Esta es tu oportunidad de convertirte en leyenda del fútbol y unirte a las leyendas de LaLiga.',
              locale
            )}
            <RightGreenArrow
              optStyle={`${styles.mobileHidden} ${styles.marginTop} ${styles.marginBotton}`}
            />
            <span className={`${styles.contentSpan} ${styles.italic} ${styles.bold}`}>
              {tt('¿Estás listo para alcanzar la gloria?', locale)}
            </span>
            <span className={`${styles.contentSpan} ${styles.bold}`}>
              {tt('Compra ahora tus entradas y vive la experiencia.', locale)}
            </span>
          </p>
          <CtaButton locale={locale} optStyle={styles.mobileHidden} />
        </div>
      </div>
      <div className={`${styles.contentWrapper} ${styles.desktopHidden}`}>
        <CtaButton locale={locale} />
      </div>
    </div>
  </section>
);

const TheBeatChallengeSecondaryContent = ({ locale, data }) => (
  <>
    <div className={`${styles.secondaryContentWrapper} ${styles.secondaryContent}`}>
      <h2 className={`${styles.secondaryContentTitle} ${styles.bold}`}>
        {tt('DESCUBRE EL NUEVO RESTAURANTE', locale)}
        <span className={styles.italic}>{` ${tt(
          'LALIGA TWENTYNINE’S PORTAVENTURA WORLD',
          locale
        )}`}</span>
      </h2>
      <img
        className={`${styles.secondaryContentMobileImg} ${styles.desktopHidden}`}
        src={LaLiga3}
        alt="la-liga-3"
      />
      <img
        className={`${styles.secondaryContentDesktopImg} ${styles.mobileHidden}`}
        src={LaLiga3Desktop}
        alt="la-liga-3-desktop"
      />
      <p className={styles.secondaryContentText}>
        {tt('Este nuevo proyecto se complementa con', locale)}
        <Link
          className={`${styles.italic} ${styles.bold}`}
          target="_blank"
          href={tt(
            'https://www.laliga29s.com/restaurante/laliga-twentynines-portaventuraworld',
            locale
          )}
        >
          {` “${tt('LaLiga TwentyNine’s PortAventura World', locale)}”`}
        </Link>
        {`, ${tt(
          'el primer restaurante del mundo tematizado de LaLiga. Este nuevo concepto de sports bar propone una experiencia gastronómica en un espacio innovador, donde podrás sentirte inmerso en el mundo de LaLiga. Disfruta de los partidos en directo de tu equipo desde la grada habilitada así como de otros deportes, desafía a tus amigos y familiares en la zona de eSports o descubre la experiencia en realidad aumentada en el espacio de The Beat Challenge.',
          locale
        )}`}
      </p>
      <br />
      <p className={styles.secondaryContentText}>
        {tt(
          'LaLiga TwentyNine’s está ubicado en el acceso principal de PortAventura World y para disfrutar del restaurante no es necesario tener una entrada para los parques temáticos. Podrás llegar a este nuevo espacio a pie por la avenida Alcalde Pere Molas o aparcando en nuestro parking de forma GRATUITA.',
          locale
        )}
      </p>
      <br />
      <p
        className={styles.secondaryContentText}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: tt(
            'Disfruta de descuentos exclusivos. <a href="https://www.portaventuraworld.com/laliga-twenty-nines">¡Más info!</a>',
            locale
          ),
        }}
      />
      <p className={styles.bold}>
        {tt('Reserva tu mesa y vive esta nueva experiencia en PortAventura World.', locale)}
      </p>
      <div className={`${styles.contentWrapper}`}>
        <RestaurantCtaButton locale={locale} />
      </div>
      <Loki data={data} />
    </div>
    <h2 className={`${styles.secondaryContentTitle} ${styles.secondaryContent} ${styles.bold}`}>
      {tt(
        'The Beat Challenge: un videojuego inmersivo con tecnología de realidad aumentada.',
        locale
      )}
    </h2>
  </>
);

const IsMobileTheBeatChallengeQR = ({ locale }) => (
  <>
    <p className={`${styles.bold} ${styles.qrInfo}`}>
      <span>
        {`¡${tt('Descarga la app', locale)} `}
        <span>
          <Link href="https://thebeatchallenge.onelink.me/blrI">{`${tt('aquí', locale)}`}</Link>!
        </span>
      </span>
    </p>
  </>
);

const IsDesktopTheBeatChallengeQR = ({ locale }) => (
  <>
    <p className={`${styles.bold} ${styles.qrInfo}`}>{`¡${tt('Descarga la app aquí', locale)}!`}</p>
    <img className={styles.qr} src={QR} alt="QR" />
  </>
);

const laligaPruebas = ({
  data: {
    allLaligaPruebasSeoBlock,
    allLaligaPruebasJumboText,
    allLaligaPruebasJumboImage,
    allLaligaPruebasMobileHeaderImage,
    allLaligaPruebasBreadcrumbBlock,
    allLaligaPruebasSeoRichData,
    allLaligaPruebasLokiBlock,
  },
  pageContext: { locale, pageName, url, options },
}) => {
  const heimdallData = {
    name: allLaligaPruebasJumboText.edges[0].node.title,
    subtitle: allLaligaPruebasJumboText.edges[0].node.subtitle,
    image: {
      url: allLaligaPruebasJumboImage.edges[0].node.localImage.childImageSharp.fluid,
      alt: allLaligaPruebasJumboImage.edges[0].node.alt,
    },
    imageMobile: {
      alt: allLaligaPruebasMobileHeaderImage.edges[0].node.alt,
      url: allLaligaPruebasMobileHeaderImage.edges[0].node.localImage.childImageSharp.fluid,
    },
  };

  const lokiData = {
    arrayImg: allLaligaPruebasLokiBlock.nodes
      .map((block) => ({
        img: block.localImage ? block.localImage.childImageSharp.fluid : null,
        altImage: block.image_alt,
      }))
      .filter((item) => item.img !== null),

    bigArrayImg: allLaligaPruebasLokiBlock.nodes
      .map((block) => ({
        img: block.localImage ? block.localImage.childImageSharp.fluid : null,
        altImage: block.image_alt,
      }))
      .filter((item) => item.img !== null),
  };

  return (
    <Layout locale={locale} pageName={pageName} options={options} layoutFromOld>
      <SEO
        title={allLaligaPruebasSeoBlock.edges[0].node._0.title}
        description={allLaligaPruebasSeoBlock.edges[0].node._1.description}
        pathname={url}
        lang={locale}
        imageRich={allLaligaPruebasSeoRichData.edges[0].node.rich_markup.imgRich}
      />
      <Hod
        data={allLaligaPruebasSeoRichData.edges[0].node.rich_markup}
        url={url}
        img={allLaligaPruebasSeoRichData.edges[0].node.image}
      />
      <div className="is-fullWidth">
        <Heimdall data={heimdallData} />
        <div className="general-index">
          <div className={styles.theBeatChallengeContent}>
            <NeoAesir
              locale={locale}
              pageName={pageName}
              title={allLaligaPruebasBreadcrumbBlock.edges[0].node.name}
            />
            <TheBeatChallengeBanner locale={locale} />
            <div className={styles.laLigaImage}>
              <iframe
                title="laLiga"
                className="laLigaVideo"
                frameBorder="0"
                allowFullScreen="allowfullscreen"
                src="https://www.youtube.com/embed/QzRSIvms0JA"
              />
            </div>
            <TheBeatChallengeSecondaryContent locale={locale} data={lokiData} />
            <DownGreenArrow />
            <TheBeatChallengeContent locale={locale} />
            <MediaServerRender predicted="mobile" hydrated>
              <MediaMatcher
                mobile={<IsMobileTheBeatChallengeQR locale={locale} />}
                tablet={<IsMobileTheBeatChallengeQR locale={locale} />}
                desktop={<IsDesktopTheBeatChallengeQR locale={locale} />}
              />
            </MediaServerRender>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default laligaPruebas;

export const pageQuery = () => graphql`
  query QueryLaligaPruebas($locale: String!) {
    allLaligaPruebasSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allLaligaPruebasBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allLaligaPruebasJumboText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allLaligaPruebasJumboImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allLaligaPruebasMobileHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allLaligaPruebasSeoRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            og_description
            imgRich
          }
        }
      }
    }
    allLaligaPruebasLokiBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        localImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_alt
      }
    }
  }
`;
